<template>
  <div class="contents card">
    <el-row>
      <el-col :span="12" style="margin-left: 30px" class="user-add-form">
        <el-form
          label-position="top"
          :model="formData"
          :rules="rules"
          ref="form"
          hide-required-asterisk
        >
          <el-form-item label="账号" prop="account">
            <el-input
              v-model="formData.account"
              maxlength="16"
              :disabled="!!formData.usrId"
              placeholder="请输入账号,最多16个字符,支持数字、字母、下划线"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户名称" prop="userName">
            <el-input
              maxlength="20"
              v-model="formData.userName"
              placeholder="请输入用户名，最多20个字符、支持数字、字母、下划线、汉字"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属组织" prop="deptId">
            <select-tree
              ref="selectTree"
              :props="props"
              v-if="loading.tree"
              placeholder="请选择组织"
              :options="organization"
              :value="formData.deptId"
              :clearable="true"
              :accordion="true"
              @getValue="getValue($event)"
            />
          </el-form-item>
          <el-form-item label="角色名称" prop="roleIds">
            <el-select
              style="width: 100%"
              v-model="formData.roleIds"
              multiple
              placeholder="请选择角色"
            >
              <el-option
                v-for="item in dict.roleList"
                :key="item.roleId"
                :label="item.roleName"
                :value="item.roleId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号" prop="telPhone">
            <el-input
              :disabled="!!formData.usrId"
              v-model="formData.telPhone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="座机号" prop="phoneNum">
            <el-input
              v-model="formData.phoneNum"
              placeholder="请输入座机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="Emall" prop="email">
            <el-input
              v-model="formData.email"
              placeholder="请输入邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item label="指定用户是否是管理员" prop="manageType">
            <el-radio-group v-model="formData.manageType">
              <el-radio v-model="formData.manageType" :label="1">是</el-radio>
              <el-radio v-model="formData.manageType" :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" prop="description">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4 }"
              maxlength="200"
              resize="none"
              placeholder="最多输入200个字符,支持英文、字母、下划线汉字"
              v-model="formData.description"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <el-button class="button_cance import button_cance_" @click="cancle"
          >取消</el-button
        >
        <el-button class="button_preserve" type="primary" @click="handleSave"
          >保存</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  userUsers,
  roleQuUserRole,
  userQueryUserInfo
} from "@/api/systemAdmin/userAdmin.js";
import { queryDeptAll } from "../../api/AssetManagement";

export default {
  name: "addUser",
  data() {
    return {
      organization: [],
      formData: {
        account: "",
        deptId: "",
        userName: "",
        roleIds: [],
        telPhone: "",
        phoneNum: "",
        manageType: "",
        email: "",
        description: ""
      },
      props: {
        value: "deptId",
        label: "deptName",
        children: "children"
      },
      loading: {
        tree: false
      },
      dict: {
        roleList: []
      },
      rules: {
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "change"
          },
          {
            validator: (rule, value, callback) => {
              if (/^\w+$/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入数字字母下划线!"));
              }
            },
            trigger: "change"
          }
        ],
        deptId: [
          {
            required: true,
            message: "请选择所属组织",
            trigger: "change"
          }
        ],
        userName: [
          {
            required: true,
            message: "请输入用户名称",
            trigger: "change"
          },
          {
            validator: (rule, value, callback) => {
              if (/^[a-zA-Z0-9_\u4e00-\u9fa5]+$/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入数字字母下划线汉字!"));
              }
            },
            trigger: "change"
          }
        ],
        roleIds: [
          {
            required: true,
            message: "请选择角色名称",
            trigger: "blur"
          }
        ],
        telPhone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "change"
          },
          {
            validator: (rule, value, callback) => {
              if (/^1[3|4|5|7|8][0-9]{9}$/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入正确的手机号!"));
              }
            },
            trigger: "change"
          }
        ],
        phoneNum: [
          // {
          //   required: true,
          //   message: "请输入座机号",
          //   trigger: "change"
          // }
        ],
        email: [
          // {
          //   required: true,
          //   message: "请输入邮箱",
          //   trigger: "change"
          // },
          {
            validator: (rule, value, callback) => {
              if (
                !/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
                  value
                ) &&
                value
              ) {
                callback(new Error("请输入正确的邮箱!"));
              } else {
                callback();
              }
            },
            trigger: "change"
          }
        ],
        manageType: [
          {
            required: true,
            message: "请指定用户是否是管理员",
            trigger: "change"
          }
        ],
        description: [
          {
            validator: (rule, value, callback) => {
              if (value) {
                if (/^[，,。.a-zA-Z0-9_\u4e00-\u9fa5]+$/.test(value)) {
                  callback();
                } else {
                  callback(new Error("请输入数字字母下划线汉字!"));
                }
              } else {
                callback();
              }
            },
            trigger: "change"
          }
        ]
      }
    };
  },
  created() {
    this.getorganization();
    this.roleQuUserRole();
    this.formData = Object.assign(this.formData, { ...this.$route.query });
    if (this.$route.query.usrId) {
      userQueryUserInfo(this.$route.query.usrId).then(({ data }) => {
        this.formData = { ...this.formData, ...data.data };
        if (data.data.roleAndFns.length)
          this.formData.roleIds = data.data.roleAndFns.map(value => {
            return value.roleId;
          });
      });
    }
  },
  methods: {
    getValue(value) {
      this.formData.deptId = value;
    },
    getorganization() {
      const config = {
        params: {
          userId: localStorage.getItem("userId")
        }
      };
      queryDeptAll(config).then(({ data }) => {
        const arr = data.data || [];
        arr.forEach(value => {
          value.children = [];
          arr.forEach(val => {
            if (value.deptId === val.parentId) {
              value.children.push(val);
            }
          });
          // if (value.parentId === 0) {
          //   this.organization.push(value);
          // }
          if (value.deptId === parseInt(localStorage.getItem("deptId"), 10)) {
            this.organization.push(value);
          }
        });
        this.loading.tree = true;
      });
    },
    //获取角色列表
    roleQuUserRole() {
      const config = {
        params: {
          userId: localStorage.getItem("userId"),
          page: 1,
          limit: 9999
        }
      };
      roleQuUserRole(config).then(({ data }) => {
        this.dict.roleList = data.data.list;
      });
    },
    //保存
    handleSave() {
      console.log(this.formData);
      this.$refs["form"].validate(valid => {
        if (valid) {
          const config = {
            data: {
              ...this.formData
            }
          };
          userUsers(config).then(({ data }) => {
            if (data.resultCode === 0) {
              this.$message({
                showClose: true,
                message: `${this.formData.type}成功`,
                type: "success"
              });
              this.$router.push({ path: "/dashboard/systemSet/Control" });
            } else {
              this.$message({
                showClose: true,
                message: data.errorDesc || `${this.formData.type}失败`,
                type: "error"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancle() {
      this.$router.push({ name: "用户管理" });
    },
    handleChange(value) {
      console.log(value);
    },
    nodeClick(data) {
      console.log(data);
    }
  }
};
</script>

<style lang="scss">
.contents {
  width: 100%;
  background: #fff;
  padding-top: 30px;
  border: 1px solid #e8eaf0;
  border-radius: 3px;
}
@import "@/style/systemSet/addUser.scss";
.expanded .el-tree-node__expand-icon {
  // padding-left: 0 !important;
}
.tree_content {
  margin-left: 50px;
}
.button_cance_ {
  width: 140px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.user-add-form {
  .el-form-item {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-form-item__label {
    font-weight: normal;
    font-size: 14px;
    color: #858585;
    width: 100%;
    text-align: left;
    line-height: 1;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
</style>
